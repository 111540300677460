 function formatDateTime(dateString) {
    // Створюємо об'єкт Date з datetime string
    const dateObj = new Date(dateString);
    // Отримуємо окремі частини дати та часу
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // місяці в JavaScript нумеруються з 0
    const year = dateObj.getUTCFullYear();
    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    // Повертаємо форматовану дату і час
    return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

// Шукаємо всі теги <date> на сторінці
function dateConverter ()
{
  if (document.querySelector('time')) {
  document.querySelectorAll('time').forEach(el => {
    let dateTimeAttr = el.getAttribute('datetime');
    el.innerHTML = formatDateTime(dateTimeAttr);
  });
  }
}
// dateConverter();

function truncator ()
{
  const posts = document.querySelectorAll('.news-item');
  if (posts.length>0) {
    posts.forEach(el =>
    {
      el.querySelector('.news-item__title').innerHTML = truncate(el.querySelector('.news-item__title').innerHTML, 75)
      el.querySelector('.news-item__text').innerHTML = truncate(el.querySelector('.news-item__text').innerHTML, 120)
    })
  }
  function truncate (str, max)
  {
    return (str.length>max)?str.slice(0, max - 1)+"...":str
  }
}
truncator();