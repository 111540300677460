// document.addEventListener('DOMContentLoaded', function () {
//     const allDetails = document.querySelectorAll('details');

//     allDetails.forEach(details => {
//         const content = details.querySelector('.details-content');
//         const summary = details.querySelector('summary');

//         summary.addEventListener('click', function () {
//             if (details.hasAttribute('open')) {
//                 content.style.maxHeight = 0;
//                 content.style.padding = '0';
//             } else {
//                 content.style.padding = '.5em';
//                 content.style.maxHeight = content.scrollHeight + 'px';
//             }
//         });

//         details.addEventListener('toggle', function () {
//             if (!details.hasAttribute('open')) {
//                 content.style.padding = '0';
//                 content.style.maxHeight = 0;
//             } else {
//                 content.style.padding = '.5em';
//                 content.style.maxHeight = content.scrollHeight + 'px';
//             }
//         });
//     });
// });