function caseCount() {
    if (document.querySelector('.countup')) {
        const animationDuration = 4000
        const frameDuration = 1000 / 60
        const totalFrames = Math.round(animationDuration / frameDuration)
        const easeOutQuad = (t) => t * (2 - t)
        const animateCountUp = (el) => {
            let frame = 0
            const countTo = parseInt(el.innerHTML, 10)

            const counter = setInterval(() => {
                frame++
                const progress = easeOutQuad(frame / totalFrames)
                const currentCount = Math.round(countTo * progress)
                if (parseInt(el.innerHTML, 10) !== currentCount) {
                    el.innerHTML = currentCount
                }
                if (frame === totalFrames) {
                    clearInterval(counter)
                }
            }, frameDuration)
        }

        const countupEls = document.querySelectorAll('.countup')

        document.addEventListener('scroll', startCount)

        function startCount() {
            if (
                countupEls.length &&
                window.scrollY >
                    document.querySelector('.counters').offsetTop -
                        window.innerHeight
            ) {
                countupEls.forEach(animateCountUp)
                document.removeEventListener('scroll', startCount)
            }
        }
    }
}

caseCount()
