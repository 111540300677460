function getCategoriesList() {
    if (document.querySelector('.category-select')) {
        new TomSelect('#category-select', {
            maxItems: 1,
            plugins: {
                input_autogrow: true,
            },
        })
    }
}
getCategoriesList()
