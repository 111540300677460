function inputHidePlaceholder ()
{
  if (document.querySelector('.input-wrapper__field')) {
    let allInputs = document.querySelectorAll('.input-wrapper__field');
    allInputs.forEach(el =>
    {
      el.addEventListener('change', placeholderFunc);
    });
    function placeholderFunc (e)
    {
      if (e.currentTarget.value !== '') {
        e.currentTarget.parentElement.querySelector('.input-wrapper__placeholder').setAttribute('hidden', 'true');
      } else {
        e.currentTarget.parentElement.querySelector('.input-wrapper__placeholder').removeAttribute('hidden');
      }
    }
  }
}
inputHidePlaceholder();


  // textarea autoGrow
  function autoGrow(element) {
  element.style.height = "5px";
  element.style.height = (element.scrollHeight) + "px";
}