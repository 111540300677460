function feedbackFormWrapper ()
{
  // functions initiators
  function authorSelect ()
  {
    if (document.querySelector('#feedback-form-author')) {
      new TomSelect('#feedback-form-author', {
        maxItems: 1,
        plugins: {
          input_autogrow: true,
        },
      })
    }
  }

  function officeSelect ()
  {
    if (document.querySelector('#feedback-form-staff-office')) {
      new TomSelect('#feedback-form-staff-office', {
        maxItems: 1,
        plugins: {
          input_autogrow: true,
        },
      })
    }
  }
  function formTypeSelect ()
  {
    if (document.querySelector('#feedback-form-type')) {
      new TomSelect('#feedback-form-type', {
        maxItems: 1,
        plugins: {
          input_autogrow: true,
        }
      })
    }
  }
  function qualityAssessmentSelect ()
  {
    if (document.querySelector('#feedback-form-quality-assessment')) {
      new TomSelect('#feedback-form-quality-assessment', {
        maxItems: 1,
        plugins: {
          input_autogrow: true,
        }
      })
    }
  }
  function authorApplySelect ()
  {
    if (document.querySelector('#feedback-form-author-apply')) {
      new TomSelect('#feedback-form-author-apply', {
        maxItems: 1,
        plugins: {
          input_autogrow: true,
        }
      })
    }
  }
  function feedbackOfficeVisits ()
  {
    if (document.querySelector('#feedback-form-office-visitor')) {
      new TomSelect('#feedback-form-office-visitor', {
        maxItems: 1,
        plugins: {
          input_autogrow: true,
        }
      })
    }
  }
  function compliantTopicSelect() {
        if (document.querySelector('#feedback-form-compliant-topic')) {
      new TomSelect('#feedback-form-compliant-topic', {
      plugins: {
                caret_position: true,
                input_autogrow: true,
                remove_button: true,
            },
            persist: false,
        create: true,
      }).on('change', compliantGradeSelect)
    }
  }
  if (document.querySelector('.feedback-form')) {
    authorSelect();
    officeSelect();
    formTypeSelect();
    qualityAssessmentSelect();
    authorApplySelect();
    feedbackOfficeVisits();
    compliantTopicSelect();
  }

  // functions for selectors
  function selectOptions ()
  {
    document.querySelectorAll('[data-type="select-option"]').forEach(
      el =>
      {
        el.addEventListener('input', makeOption)
      }
    )
    function makeOption (e)
    {
      let choice = e.target.selectedOptions[0].dataset.value;
      let switcher = e.target.id;
      let switchedOptions = e.target.closest('.feedback-form').querySelectorAll(`[data-switcher="${ switcher }"]`);
      switchedOptions.forEach(el =>
      {
        if (el.dataset.option == choice) {
          el.hidden = false;
          el.querySelectorAll('input, textarea, select').forEach(
            input =>
            {
              if (el.querySelectorAll('input, textarea, select').length && input.dataset.required === "required") {
                input.setAttribute('required', true)
                if (input.nextElementSibling && input.nextElementSibling.classList.contains('input__wrapper-placeholder')) {
                  input.nextElementSibling.hidden = false;
                }
              }
            }
          )
        }
        else if (el.querySelectorAll('input, textarea, select').length) {
          el.hidden = true;
          el.querySelectorAll('input, textarea, select').forEach(
            input =>
            {
              input.value = null;
              input.removeAttribute('required');
            }
          )
        }
      }
      )
    }
  }
  selectOptions();

  function compliantGradeSelect ()
  {
    let grade_input = document.getElementById('feedback-form-potential-grade');
    let selected_options = Array.from(this.input.selectedOptions);
    let grade = 0;
    if (selected_options.length>0) {
      selected_options.forEach(el =>
    {
      if (parseInt(el.dataset.compliantGrade) > grade) {
        grade=el.dataset.compliantGrade
      }
    }
    )
    }     
    grade_input.value = grade;
  }

}
feedbackFormWrapper();