function burger ()
{
  let burgerBtn = document.querySelector('.header__burger');
  let mobileMenu = document.querySelector('.header__mobile-wrapper');
  if (window.matchMedia('(max-width: 1023px)').matches) {
    mobileMenu.appendChild(document.querySelector('.header__menu-list'))
    mobileMenu.querySelector('.header__mobile-bottom').appendChild(document.querySelector('.header__lang-button'))
    mobileMenu.querySelector('.header__mobile-bottom').appendChild(document.querySelector('.header__search-button'))
  }
  burgerBtn.addEventListener('click', burgerClick)
  function burgerClick (e)
  {
    if (!e.currentTarget.classList.contains('--is-open')) {
      e.currentTarget.classList.add('--is-open');
      mobileMenuOpen()
    } else {
      e.currentTarget.classList.remove('--is-open');
      mobileMenuClose()
    }
  }
  function mobileMenuOpen ()
  {
    mobileMenu.classList.add('--is-open')
   };
  function mobileMenuClose ()
  {
    mobileMenu.classList.remove('--is-open')
  }
}
burger();

function scrollHeader ()
{
  const header = document.querySelector('.header');
  window.addEventListener('scroll', () =>
  {
    let scroll = window.scrollY;
    let height = window.innerHeight;
        if (document.querySelector('.hero')) {
      height = document.querySelector('.hero').scrollHeight;
    } else if(document.querySelector('.small-hero')) {
      height = document.querySelector('.small-hero').scrollHeight;
        } else if (document.querySelector('.post-hero')) {
          height = document.querySelector('.post-hero').scrollHeight;
    }
    if (scroll >= (height-50)) {
      header.classList.add('--scrolled')
    } else {
      header.classList.remove('--scrolled')
    }
  })
}
scrollHeader();