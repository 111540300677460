function descriptionSlider ()
{
  if (document.querySelector('.description__col.splide')) {
    new Splide('.description__col.splide', {
      type: 'fade',
      rewind: true,
      arrows: true,
      pagination: false,
      drag: false,
      snap: true,
      autoplay: true,
      speed: 2000,
    }).mount()
  }
 }
descriptionSlider();