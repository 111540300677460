function mapChange ()
{
  if(document.querySelector('.contacts__address-item')&&document.querySelector('.contacts__address-item').dataset.addressLink){
    const offices = document.querySelectorAll('.contacts__address-item');
    offices.forEach(el => {
      el.addEventListener('mouseover', setUrl);
    });
    function setUrl (e)
    {
      const mapFrame = document.querySelector('.contacts__map iframe');
      if(mapFrame.src!==e.currentTarget.dataset.addressLink){
        mapFrame.src = e.currentTarget.dataset.addressLink;
      }
    }
  }
}
mapChange();