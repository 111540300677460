function getTagsList() {
    if (document.querySelector('.tag-select')) {
        new TomSelect('#tag-select', {
            // maxItems: 8,
            plugins: {
                caret_position: true,
                input_autogrow: true,
                remove_button: true,
            },
            persist: false,
            create: true,
        })
    }
}
getTagsList()
