function goToTopBtn() {
    let goBtn = document.querySelector('.footer__go-to-top')
    if (goBtn) {
        goBtn.addEventListener('click', () => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        })
        window.addEventListener('scroll', () => {
            if (
                window.scrollY > 100 &&
                window.scrollY <
                    document.body.scrollHeight -
                        document.querySelector('.footer').scrollHeight -
                        window.innerHeight
            ) {
                goBtn.classList.add('--active')
            } else {
                goBtn.classList.remove('--active')
            }
        })
    }
}
goToTopBtn()
