function donationBlockShowHide ()
{ 
  if(document.querySelector('.donation')){
    const donationBlock = document.querySelector('.donation');
    const donationBlockButton = document.querySelector('.donation__close');

    if (sessionStorage.donationBlockHide !== 'true') {
      donationBlock.style.display = 'block';
      donationBlockButton.addEventListener('click', closeDonationBlock);
  } else {
    }
      function closeDonationBlock() {
        sessionStorage.setItem('donationBlockHide', true);
        donationBlock.removeAttribute('style');
      }
  }
}
donationBlockShowHide()