function partnersSlider ()
{
  if (document.querySelector('.partners__slider.splide')) {
    new Splide('.partners__slider.splide', {
      type: 'loop',
      autoWidth: true,
      gap: '2em',
      arrows: false,
      pagination: false,
      focus: 'center',
      rewind: true,
      drag: "free",
      autoplay: true,
      speed: 5000,
      interval: 5000,
      easing: 'linear',
      pauseOnHover: true
    }
    ).mount()
  }
}
partnersSlider();